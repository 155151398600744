import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Purchase, PurchasesQueryResponse} from './_models'

const API_URL_NEW = process.env.REACT_APP_API_URL_NEW
const API_URL = process.env.REACT_APP_THEME_API_URL
const PURCHASE_URL = `${API_URL}/purchase`
const GET_PURCHASES_URL = `${API_URL}/users/query`
const GET_PURCHASES_URL_NEW = `${API_URL_NEW}/purchase-order`
const GET_BUYER_LIST_URL = `${API_URL_NEW}/buyer-seller`
const GET_UPLOAD = `${API_URL_NEW}/uploads/upload`
const SENDPOEMAIL = `${API_URL_NEW}/purchase-order/send-po-mail`
const PO_STATUS_LIST = `${API_URL_NEW}/purchase-order/list/status?page=1&perPage=50`
const GET_GROUP_URL = `${API_URL_NEW}/product/group/list/all?perPage=100&page=1`

const getPoBuyyerFilter = (): Promise<any> => {
  const setQuery = `type=1&perPage=20&page=1`
  return axios.get(`${GET_BUYER_LIST_URL}?${setQuery}`).then((d: AxiosResponse<any>) => d.data)
}
const getGroupList = (): Promise<PurchasesQueryResponse> => {
  return axios.get(`${GET_GROUP_URL}`).then((d: AxiosResponse<PurchasesQueryResponse>) => d.data)
}

const getPurchaseNew = (query: string): Promise<PurchasesQueryResponse> => {
  return axios
    .get(`${GET_PURCHASES_URL_NEW}?${query}`)
    .then((d: AxiosResponse<PurchasesQueryResponse>) => d.data)
}

const getPurchase = (query: string): Promise<PurchasesQueryResponse> => {
  return axios
    .get(`${GET_PURCHASES_URL}?${query}`)
    .then((d: AxiosResponse<PurchasesQueryResponse>) => d.data)
}

const getPurchaseByIdNew = (id: string): Promise<any | undefined> => {
  return axios
    .get(`${GET_PURCHASES_URL_NEW}/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response)
}

const getPurchaseById = (id: ID): Promise<Purchase | undefined> => {
  return axios
    .get(`${PURCHASE_URL}/${id}`)
    .then((response: AxiosResponse<Response<Purchase>>) => response.data)
    .then((response: Response<Purchase>) => response.data)
}

const createPurchaseNew = (purchase: any): Promise<any | undefined> => {
  return axios
    .post(GET_PURCHASES_URL_NEW, purchase)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response)
}

const createPurchase = (purchase: Purchase): Promise<Purchase | undefined> => {
  return axios
    .put(PURCHASE_URL, purchase)
    .then((response: AxiosResponse<Response<Purchase>>) => response.data)
    .then((response: Response<Purchase>) => response.data)
}

const updatePurchaseNew = (id: string, purchase: any): Promise<any | undefined> => {
  return axios
    .patch(`${GET_PURCHASES_URL_NEW}/${id}`, purchase)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response)
}

const updatePurchase = (purchase: Purchase): Promise<Purchase | undefined> => {
  return axios
    .post(`${PURCHASE_URL}/${purchase.id}`, purchase)
    .then((response: AxiosResponse<Response<Purchase>>) => response.data)
    .then((response: Response<Purchase>) => response.data)
}

const deletePurchase = (purchaseId: ID): Promise<void> => {
  return axios.delete(`${PURCHASE_URL}/${purchaseId}`).then(() => {})
}

const deletePurchaseNew = (purchaseId: string): Promise<void> => {
  return axios.delete(`${GET_PURCHASES_URL_NEW}/${purchaseId}`).then(() => {})
}

const deleteSelectedPurchases = (purchaseIds: Array<ID>): Promise<void> => {
  const requests = purchaseIds.map((id) => axios.delete(`${PURCHASE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const uploadFile = (formData: any): Promise<any | undefined> => {
  return axios.post(GET_UPLOAD, formData).then((response: AxiosResponse<Response<any>>) => response.data)
}

const sendEmailPurchaseOrder = (purchaseId: any): Promise<any | undefined> => {
  return axios
    .get(`${SENDPOEMAIL}/${purchaseId}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const getPOStatusList = (): Promise<any> => {
  return axios.get(`${PO_STATUS_LIST}`).then((d: AxiosResponse<any>) => d.data)
}

export {
  getGroupList,
  getPurchaseNew,
  getPurchase,
  deletePurchase,
  getPoBuyyerFilter,
  deleteSelectedPurchases,
  getPurchaseById,
  createPurchase,
  updatePurchase,
  createPurchaseNew,
  getPurchaseByIdNew,
  updatePurchaseNew,
  deletePurchaseNew,
  uploadFile,
  sendEmailPurchaseOrder,
  getPOStatusList
}
